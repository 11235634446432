
import {Options, Prop, Vue, Watch} from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";
import GetEndpointLogDataResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/LogGetterService/GetEndpointLogDataResponse";
import GetEndpointLogDetailsResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/LogGetterService/GetEndpointLogDetailsResponse";

@Options({
  name: "LogsViewDetailPage",
  components: {
    TableView,
  },
})
export default class ContentLogsDateailView extends Vue {
  @Prop({default: new FilterModel()}) filter: FilterModel;
  @Prop({default: null}) slotProps: {
    sourceLogId: Number;
    filterChange: Boolean;
  };
  innerFilter: string;
  item: GetEndpointLogDetailsResponse = null;
  itemDataLogs: GetEndpointLogDataResponse = null;
  requestId: string = "";
  page: PageRequest = {Take: 20, Skip: 0};
  count = 0;
  noPagination = false;
  isLoading = false;
  error1 = {
    isSuccess: true,
    errorMessage: ""
  }
  error2 = {
    isSuccess: true,
    errorMessage: ""
  }

  @Watch("filter", {deep: true})
  onFilter() {
    // this.refresh();
  }

  created(): void {
    if (this.$route.params.id) this.requestId = String(this.$route.params.id);
  }

  mounted() {
    this.getEndpointLogDetails();
    this.getEndpointLogData();
  }

  async getEndpointLogDetails() {
    this.isLoading = true;
    try {
      let response =
          await this.$api.LogGetterService.getEndpointLogDetailsAsync(
              `"${this.requestId}"`
          );
      if (response) {
        this.item = response;
        console.log(this.item, "item");
      }
    } catch (error) {
      this.error1.isSuccess = false;
      this.error1.errorMessage = error;
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  async getEndpointLogData() {
    this.isLoading = true;
    try {
      let response = await this.$api.LogGetterService.getEndpointLogDataAsync(
          `"${this.requestId}"`
      );
      if (response) {
        this.itemDataLogs = response;
        console.log(this.itemDataLogs, "itemDataLogs");
      }
    } catch (error) {
      this.error2.isSuccess = false;
      this.error2.errorMessage = error;
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  //событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.page.Take;
    if (this.page.Skip === skip) {
      return;
    }
    this.page.Skip = skip;
    this.getEndpointLogDetails();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }

  onReadMore(id: string) {
    console.log(`id=`, id);
  }
}
