<template>
  <page name="Детальная страница методов логов">
    <template v-slot="slotProps">
      <div class="logs-view">
        <tirscript3-loading :loading="isLoading"></tirscript3-loading>
        <div v-if="item || itemDataLogs" class="detail-method-block">
          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">Источник</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">
                {{ item.ServerName }} / {{ item.ProjectName }} ({{
                  item.ProjectLogGroupId
                }})
              </div>
              <div
                  v-if="!item.ProjectName && !item.ServerName"
                  class="prop no-info"
              >
                нет данных
              </div>
            </div>
          </div>

          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">Метод</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">{{ item.Class }} / {{ item.Method }}</div>
              <div v-if="!item.Class && !item.Method" class="prop no-info">
                нет данных
              </div>
            </div>
          </div>

          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">DateStart / DateEnd</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">
                {{ $filters.DateFormat(item.DateStart) }} /
                {{ $filters.DateFormat(item.DateEnd) }}
                <br/>
                {{ $filters.IntervalMs(item.DateStart, item.DateEnd) }}
              </div>
              <div v-if="!item.DateStart && !item.DateEnd" class="prop no-info">
                нет данных
              </div>
            </div>
          </div>

          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">RequestLength</div>
            </div>
            <div class="col-9 detail-method-col">
              <div v-if="item.RequestLength >= 0" class="prop">
                {{ item.RequestLength }} байт
              </div>
              <div v-else class="prop no-info">нет данных</div>
            </div>
          </div>

          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">ResponseLength</div>
            </div>
            <div class="col-9 detail-method-col">
              <div v-if="item.ResponseLength >= 0" class="prop">
                {{ item.ResponseLength }} байт
              </div>
              <div v-else class="prop no-info">нет данных</div>
            </div>
          </div>

          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">LogLevel</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">{{ $filters.LogLevel(item.LogLevel) }}</div>
              <div v-if="!item.LogLevel" class="prop no-info">нет данных</div>
            </div>
          </div>

          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">UserId</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">{{ item.UserId }}</div>
              <div v-if="!item.UserId" class="prop no-info">нет данных</div>
            </div>
          </div>

          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">Ip</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">{{ item.Ip }}</div>
              <div v-if="!item.Ip" class="prop no-info">нет данных</div>
            </div>
          </div>
          <!-- <div class="row detail-method-row">
            <div class="col-6 detail-method-col">
              <div class="key">LogType</div>
            </div>
            <div class="col-6 detail-method-col">
              <div class="prop" >{{item.LogType}}</div>
              <div class="prop no-info" v-if="!item.LogType">нет данных</div>
            </div>
          </div> -->

          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">RequestId</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">{{ requestId }}</div>
              <div v-if="!requestId" class="prop no-info">нет данных</div>
            </div>
          </div>
          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">SessionToken</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">{{ item.SessionToken }}</div>
              <div v-if="!item.SessionToken" class="prop no-info">
                нет данных
              </div>
            </div>
          </div>

          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">ConnectionId</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">{{ item.ConnectionId }}</div>
              <div v-if="!item.ConnectionId" class="prop no-info">
                нет данных
              </div>
            </div>
          </div>

          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">RequestBody</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">{{ item.RequestBody }}</div>
              <div v-if="!item.RequestBody" class="prop no-info">
                нет данных
              </div>
            </div>
          </div>
          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">UserAgent</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">{{ item.UserAgent }}</div>
              <div v-if="!item.UserAgent" class="prop no-info">нет данных</div>
            </div>
          </div>
          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">Message</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">{{ item.Message }}</div>
              <div v-if="!item.Message" class="prop no-info">нет данных</div>
            </div>
          </div>

          <div class="row detail-method-row">
            <div class="col-3 detail-method-col">
              <div class="key">Error</div>
            </div>
            <div class="col-9 detail-method-col">
              <div class="prop">{{ item.Error }}</div>
              <div v-if="!item.Error" class="prop no-info">нет данных</div>
            </div>
          </div>
        </div>
        <div v-if="!item && isLoading == false" class="row">
          <div class="pb-2 pt-4 col-12" style="text-align: center">
            Нет данных
          </div>
        </div>
      </div>
      <!--Сообщение об ошибке-->
      <div
          v-if="!error1.isSuccess"
          class="row mt-2 justify-content-center"
      >
        {{ error1.errorMessage }}
      </div>
      <!--Сообщение об ошибке-->
      <div
          v-if="!error2.isSuccess"
          class="row mt-2 justify-content-center"
      >
        {{ error2.errorMessage }}
      </div>
    </template>
  </page>
</template>
<script lang="ts">
import {Options, Prop, Vue, Watch} from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";
import GetEndpointLogDataResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/LogGetterService/GetEndpointLogDataResponse";
import GetEndpointLogDetailsResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/LogGetterService/GetEndpointLogDetailsResponse";

@Options({
  name: "LogsViewDetailPage",
  components: {
    TableView,
  },
})
export default class ContentLogsDateailView extends Vue {
  @Prop({default: new FilterModel()}) filter: FilterModel;
  @Prop({default: null}) slotProps: {
    sourceLogId: Number;
    filterChange: Boolean;
  };
  innerFilter: string;
  item: GetEndpointLogDetailsResponse = null;
  itemDataLogs: GetEndpointLogDataResponse = null;
  requestId: string = "";
  page: PageRequest = {Take: 20, Skip: 0};
  count = 0;
  noPagination = false;
  isLoading = false;
  error1 = {
    isSuccess: true,
    errorMessage: ""
  }
  error2 = {
    isSuccess: true,
    errorMessage: ""
  }

  @Watch("filter", {deep: true})
  onFilter() {
    // this.refresh();
  }

  created(): void {
    if (this.$route.params.id) this.requestId = String(this.$route.params.id);
  }

  mounted() {
    this.getEndpointLogDetails();
    this.getEndpointLogData();
  }

  async getEndpointLogDetails() {
    this.isLoading = true;
    try {
      let response =
          await this.$api.LogGetterService.getEndpointLogDetailsAsync(
              `"${this.requestId}"`
          );
      if (response) {
        this.item = response;
        console.log(this.item, "item");
      }
    } catch (error) {
      this.error1.isSuccess = false;
      this.error1.errorMessage = error;
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  async getEndpointLogData() {
    this.isLoading = true;
    try {
      let response = await this.$api.LogGetterService.getEndpointLogDataAsync(
          `"${this.requestId}"`
      );
      if (response) {
        this.itemDataLogs = response;
        console.log(this.itemDataLogs, "itemDataLogs");
      }
    } catch (error) {
      this.error2.isSuccess = false;
      this.error2.errorMessage = error;
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  //событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.page.Take;
    if (this.page.Skip === skip) {
      return;
    }
    this.page.Skip = skip;
    this.getEndpointLogDetails();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }

  onReadMore(id: string) {
    console.log(`id=`, id);
  }
}
</script>

<style lang="less">
.logs-view {
  .detale {
    color: blue;
    cursor: pointer;
  }
}

.logs-view .tirscript3-table .tirscript3-table-header-item,
.logs-view .tirscript3-table .tirscript3-table-body-item {
  justify-content: center;
  text-align: center;
}

.detail-method-row {
  padding: 15px 0;
  margin: 0;
  border-bottom: 1px solid #ced4de;

  word-break: break-word;
}

.no-info {
  opacity: 0.5;
}
</style>